import en from "./en.json";
import fr from "./fr.json";
import ru from "./ru.json";

const sharedColumnsTranslations = {
  en,
  fr,
  ru,
};

export default sharedColumnsTranslations;