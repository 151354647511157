import { IProduct } from "@/typings/models/v2/product";
import { API_V2 } from "@/services/api";
import { IUser } from "@/typings/models/v2/user";
import { IUserViews } from "@/views/forecast/typings/views";

export const UsersAPIV2 = {

  async getMe(): Promise<IUser> {
    const { data } = await API_V2.get<IUser>(
      "/api/v2/users/me",
    );

    return data;
  },

  async getUserById(userId: number): Promise<IUser> {
    const { data } = await API_V2.get<IUser>(
      `/api/v2/users/${userId}`,
    );

    return data;
  },

  async updateUser(userId: number, user: Partial<IUser>) {
    await API_V2.patch<IUser>(
      `/api/v2/users/${userId}`,
      user,
    );
  },

  async updateScopesByUserId(userId: number, user: IUser) {
    await API_V2.put<IProduct>(
      `/api/v2/users/${userId}/scopes`,
      {
        sitesScope: user.sites.map(site => ({ id: site.id })),
      },
    );
  },

  async getViewsByUserIdAndSiteId(userId: number, siteId: number) {
    const { data } = await API_V2.get<IUserViews>(
      `/api/v2/users/${userId}/sites/${siteId}/views`,
    );

    return data;
  },

  async updateViews(userId: number, siteId: number, updatedViews: IUserViews) {
    await API_V2.patch<IUserViews>(
      `/api/v2/users/${userId}/sites/${siteId}/views`,
      updatedViews,
    );
  },
};
