import { Point } from "chart.js";

const CARET_OFFSET = 24;

export function verticalCenterPositioner(elements: any, event: Point) {
  // if no elements exist for the hover event
  if (elements.length === 0) {
    return {
      x: event.x,
      y: event.y,
    };
  }

  // @ts-ignore
  const tooltip = this;
  const elementX = elements[0].element.x;
  const { right: areaRight, height: areaHeight } = tooltip.chart.chartArea;

  const targetYPosition = areaHeight / 2;
  const shouldPlaceOnTheRight = elementX + tooltip.width + CARET_OFFSET < areaRight;

  return {
    x: elementX,
    y: targetYPosition,
    // anchor position should be vice versa
    xAlign: shouldPlaceOnTheRight ? "left" : "right",
    yAlign: "center",
  };
}
