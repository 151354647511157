import { computed } from "vue";
import { defineStore } from "pinia";
import { IUser } from "@/typings/models/v2/user";
import auth0, { WebAuth } from "auth0-js";

let client: WebAuth;

export const useAuthStore = defineStore("auth", () => {
  const token = computed({
    get() {
      return localStorage.getItem("id_token") ?? "";
    },
    set(id_token: string) {
      localStorage.setItem("id_token", id_token);
    },
  });

  const accessToken = computed({
    get() {
      return localStorage.getItem("access_token") ?? "";
    },
    set(newToken: string) {
      localStorage.setItem("access_token", newToken);
    },
  });

  const expiresAt = computed({
    get() {
      return +(localStorage.getItem("expires_at") ?? "");
    },
    set(expiresIn: number) {
      localStorage.setItem("expires_at", JSON.stringify(expiresIn * 1000 + new Date().getTime()));
    },
  });

  const user = computed({
    get() {
      return JSON.parse(localStorage.getItem("user") ?? "{}");
    },
    set(newUser: IUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
    },
  });

  const login = () => {
    client.authorize();
  };


  const logout = () => {
    return new Promise(() => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("user");

      client.logout({
        returnTo: "https://flowlity.com",
        clientID: import.meta.env.VITE_AUTH0_CLIENT_KEY,
      });
    });
  };

  const isAuthenticated = computed(() => new Date().getTime() < expiresAt.value);

  const handleAuthentication = async () => {
    return new Promise((resolve, reject) => {
      client.parseHash((err: any, authResult: any) => {
        if (authResult?.accessToken && authResult.idToken) {
          expiresAt.value = authResult.expiresIn;
          accessToken.value = authResult.accessToken;
          token.value = authResult.idToken;
          user.value = authResult.idTokenPayload;
          resolve(true);
        } else if (err) {
          logout();
          reject(err);
        }
      });
    });
  };

  return {
    accessToken,
    expiresAt,
    handleAuthentication,
    isAuthenticated,
    login,
    logout,
    token,
    user,
  };
});

export const authClientInit = () => {
  client = new auth0.WebAuth({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_AUTH0_CLIENT_KEY,
    redirectUri: `${window.location.origin}/callback`,
    audience: "https://flowlity.com",
    responseType: "token id_token",
    scope: "openid email profile",
  });
};
