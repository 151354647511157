import { API } from "@/services/api";
import { defineStore, storeToRefs } from "pinia";
import { i18n } from "@/i18n/i18n@next";
import { IUser, UserLocaleEnum } from "@/typings/models/v2/user";
import { IUserViews } from "@/views/forecast/typings/views";
import { NotificationService } from "@/services/notifications";
import { ref } from "vue";
import { useAuthStore } from "@/store/auth.store";
import { useCompanyStore } from "@/store/company.store";
import { usePreferencesStore } from "@/store/preferences.store";
import { UsersAPIV2 } from "@/services/api/users.v2.api";
import { useSiteStore } from "@/store/site.store";
import dayjs from "@/utils/dayjs";
import posthog from "posthog-js";

export const useUserStore = defineStore("user", () => {
  // we are initializing with `{} as IUser`
  // to avoid having to check everywhere if currentUser exists
  // anyway if the user is not well loaded, the app will logout and redirect to flowlity.com
  const currentUser = ref<IUser>({} as IUser);
  const userViewsForCurrentSite = ref<IUserViews>({} as IUserViews);
  const { locale } = i18n.global;
  const preferencesStore = usePreferencesStore();
  const companyStore = useCompanyStore();
  const siteStore = useSiteStore();
  const authStore = useAuthStore();

  const updateUserLocaleInBack = async (newLocale: UserLocaleEnum) => {
    const { site } = storeToRefs(useSiteStore());
    const siteId = site.value?.id;

    try {
      await API.put(
        `/api/v1/sites/${siteId}/users/${currentUser.value.id}`,
        {
          locale: newLocale,
        },
      );

      currentUser.value.locale = newLocale;

      if (newLocale === UserLocaleEnum.EN)
        NotificationService.success(
          "Your language choice was successfuly saved",
        );
      if (newLocale === UserLocaleEnum.FR)
        NotificationService.success(
          "Votre choix de langue a bien été enregistré",
        );
    } catch (error) {
      NotificationService.error(
        "Something went wrong, we could not save your language choice",
      );

      throw error;
    }
  };

  const updateUserLocaleInApp = (newLocale: UserLocaleEnum) => {
    // update VueI18n locale
    locale.value = newLocale;

    dayjs.updateLocale(newLocale, {
      weekStart: 1,
    });
  };

  const updateUserLocale = async (newLocale: UserLocaleEnum) => {
    updateUserLocaleInApp(newLocale);
    await updateUserLocaleInBack(newLocale);
  };

  const fetchViews = async () => {
    try {
      userViewsForCurrentSite.value = await UsersAPIV2.getViewsByUserIdAndSiteId(currentUser.value.id, siteStore.site!.id);
    } catch (e: unknown) {
      NotificationService.error("Error occurred while fetching views");
      throw e;
    }
  };

  const initMe = async () => {
    try {
      const user = await UsersAPIV2.getMe();
      currentUser.value = { ...user };

      currentUser.value.canAccess = (permissionList) => permissionList.every((permission) =>
        currentUser.value.permissions.includes(permission),
      );

      currentUser.value.hasTacticalWithEditionMode = currentUser.value.canAccess(["tactical:edition"]);
      currentUser.value.hasTacticalWithSimulationMode = currentUser.value.canAccess(["tactical:simulation"]);

      window.gtag("set", {
        user_id: user.id.toString(),
      });

      window.gtag("config", import.meta.env.VITE_GA_ID, {
        custom_map: { dimension1: "user_id" },
      });

      window.gtag("event", "custom_dimension_user_id", {
        user_id: user.id.toString(),
      });

      const isFlowlityAdmin: boolean = currentUser.value.canAccess(["admin:flowlity"]);

      const companyIdToFetch: number = isFlowlityAdmin
        ? (preferencesStore.preferences.lastUsedCompanyId ?? user.company.id)
        : user.company.id;

      await companyStore.fetchCompany(companyIdToFetch);

      const lastUsedSiteId: number | undefined = preferencesStore.preferences.lastUsedSiteId;

      const siteIdToFetch: number = isFlowlityAdmin
        // Flowlity Admin
        ? lastUsedSiteId && companyStore.company!.sites.find(s => s.id === lastUsedSiteId)
          // last used site inside the loaded company -> we select last used site
          ? lastUsedSiteId
          // last used site is not the fetched company -> checking default (assigned to user) site id
          : user.defaultSite && companyStore.company!.sites.find(s => s.id === user.defaultSite.id)
            // default site id is available in the fetched company -> we select default site id
            ? user.defaultSite.id
            // default site id is not available -> pick 1st site of the company
            : companyStore.company!.sites[0].id
        // Other Roles
        : lastUsedSiteId && user.sites.find(s => s.id === lastUsedSiteId)
          // we're able to restore last used site id
          ? lastUsedSiteId
          // we don't have access to last used site anymore:
          // -> fallback to default site or 1st assigned site or 1st site of the company
          : (user.defaultSite?.id ?? user.sites[0].id ?? companyStore.company!.sites[0].id);

      await siteStore.fetchSite(siteIdToFetch);

      // trigger posthog identify
      posthog.identify(currentUser.value.email, {
        user_id: user.id,
        company_name: companyStore?.company?.name,
        company_id: companyStore?.company?.id,
        site_name: siteStore?.site?.name,
        site_id: siteStore?.site?.id,
      });

      if (user.locale !== locale.value) {
        updateUserLocaleInApp(user.locale);
      }

      await fetchViews();
    } catch (error: any) {
      NotificationService.error("Error occurred while fetching user info", {
        description: error.message,
      });

      setTimeout(() => authStore.logout(), 5000);
    }
  };

  return {
    currentUser,
    userViewsForCurrentSite,
    initMe,
    updateUserLocale,
    fetchViews,
  };
});
