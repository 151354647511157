import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { API, API_V2 } from "@/services/api";
import { API_OR } from "@/services/or";
import { useUserStore } from "@/store/user.store";
import { useAuthStore } from "@/store/auth.store";
import { useSiteStore } from "@/store/site.store";
import { controlRbacAccess } from "@/router/middleware/admin-middleware";
import { app } from "@/main";
import { eventTracking } from "@/utils/event-tracking";
import { ROUTE } from "@/router/enum";

export const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const siteStore = useSiteStore();

  if (to.name === ROUTE.CALLBACK) {
    return next();
  } else if (authStore.isAuthenticated) {
    const email = authStore.user?.email ?? "";

    if (import.meta.env.VITE_APM_ACTIVE) {
      // set user email for APM
      app.config.globalProperties.$apm.setUserContext({
        email,
      });
    }

    if (!siteStore.site) {
      // set headers after authentication
      API.setHeader();
      API_V2.setHeader();
      API_OR.setHeader();

      const userStore = useUserStore();
      await userStore.initMe();
    }

    const routePermissions = (to.meta?.permissions as string[]) ?? [];

    controlRbacAccess(routePermissions, (toUrl, fromUrl, nextFn) => {
      if (siteStore.site!.isSupplierView && ["planning", "home"].includes(toUrl.name as string)) {
        return nextFn("/orders");
      }

      return nextFn();
    })(to, from, next);
  } else {
    // trigger auth0 login
    authStore.login();
  }
});

router.afterEach(() => {
  // track event : pageview
  // needed because our app is a SPA and hence it doesn't trigger automatically pageview events
  eventTracking("$pageview");
});
