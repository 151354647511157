export default {
  colors: {
    primary: {
      900: "#002C61",
      800: "#003B82",
      700: "#0053B5",
      600: "#0A6EE3",
      500: "#2388FF",
      400: "#A3CDFF",
      300: "#D8EAFF",
      200: "#ECF5FF",
      100: "#F6FAFF",
    },
    ui: {
      900: "#242E3D",
      800: "#303C50",
      700: "#46556A",
      600: "#637691",
      500: "#7C95B5",
      400: "#D1D9E7",
      300: "#E3EAF7",
      200: "#ECF1FB",
      100: "#F8FAFE",
    },
    green: {
      800: "#146800",
      700: "#1A8700",
      600: "#46B62C",
      500: "#7CDA66",
      400: "#B1F0A2",
      300: "#D8F9D0",
      200: "#EAFEE6",
      100: "#F5FFF2",
    },
    red: {
      800: "#B01010",
      700: "#E91010",
      600: "#F73B3B",
      500: "#FF5151",
      400: "#FFC0C0",
      300: "#FFE9E9",
      200: "#FFF4F4",
    },
    orange: {
      800: "#9C4400",
      700: "#BF5300",
      600: "#F66800",
      500: "#FF9141",
      400: "#FFD1AF",
      300: "#FFEADB",
      200: "#FFF5ED",
    },
    purple: {
      700: "#7607CD",
      600: "#AF48FF",
      500: "#C985FF",
      400: "#E5C3FF",
      300: "#F3E0FF",
      200: "#F9F0FF",
    },
    "additional-1": "#FF7557",
    "additional-2": "#E34F2F",
    "additional-3": "#B2586E",
    "additional-4": "#CA80DC",
    "additional-5": "#7856FF",
    "additional-6": "#0D7EA0",
    "additional-7": "#72BEF4",
    "additional-8": "#5BB7AF",
    "additional-9": "#3BA974",
    "additional-9-2": "#93D0B3",
    "additional-9-3": "#BAE1CE",
    "additional-10": "#F8BC3B",
    // TODO: refactor or remove colors above after new design mockups will be implemented
    "fl-green-3": "#0BF293",
    "fl-blue": "#38BFF0",
    "fl-dark-blue": "#172945",
  },
  fontSize: {
    c2: [
      "12px",
      {
        lineHeight: "18px",
        fontWeight: "400",
      },
    ],
    c1: [
      "12px",
      {
        lineHeight: "18px",
        fontWeight: "600",
      },
    ],
    p: [
      "14px",
      {
        lineHeight: "20px",
        fontWeight: "400",
      },
    ],
    h4: [
      "14px",
      {
        lineHeight: "21px",
        fontWeight: "600",
      },
    ],
    h3: [
      "16px",
      {
        lineHeight: "24px",
        fontWeight: "500",
      },
    ],
    h2: [
      "18px",
      {
        lineHeight: "27px",
        fontWeight: "500",
      },
    ],
    h1: [
      "22px",
      {
        fontWeight: "500",
      },
    ],
  },
  boxShadow: {
    l1: "0 1px 6px 0 rgba(0,0,0,0.14)",
    l2: "0 3px 12px 0 rgba(0,0,0,0.16)",
    l3: "0 7px 18px 0 rgba(0,0,0,0.2)",
    l4: "0 17px 40px 0 rgba(0,0,0,0.24)",
    "op-l1": "0 -1px 6px 0 rgba(0,0,0,0.14)",
    "op-l2": "0 -3px 12px 0 rgba(0,0,0,0.16)",
  },
  borderRadius: {
    DEFAULT: "6px",
    sm: "4px",
    none: "0",
    xs: "0.3125rem",
  },
};
