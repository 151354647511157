export enum ROUTE {
  DASHBOARD = "home",
  // tactical
  TACTICAL = "tactical",
  TACTICAL_FORECAST = "simulationForecastModal",
  // forecast
  FORECAST_LIST = "ForecastList",
  FORECAST_DETAILS = "forecastModal",
  // capacity
  CAPACITY_LIST = "capacityList",
  CAPACITY_DETAILS = "capacityModal",
  CAPACITY_DETAILS_FORECAST = "capacityUnitToForecastModalByProduct",
  // orders
  ORDERS_LIST = "orders",
  ORDERS_LIST_FORECAST = "orderListToForecastModal",
  ORDER_DETAILS = "orderDetails",
  ORDER_DETAILS_FORECAST = "orderToForecastModal",
  // analytics
  ANALYTICS = "analytics",
  // admin
  ADMIN = "admin",
  ADMIN_COMPANY = "company",
  ADMIN_TAGS_LIST = "AdminTagsList",
  ADMIN_TAG_CATEGORIES_LIST = "AdminTagCategoriesList",
  ADMIN_TAG_CATEGORY_EDIT = "AdminTagCategoryEdit",
  ADMIN_TAG_EDIT = "AdminTagEdit",
  ADMIN_SITE_EDIT = "editSite",
  ADMIN_BUFFER_POLICY = "bufferPolicy",
  ADMIN_USER_EDIT = "editUser",
  ADMIN_SUPPLIER_NEW = "newSupplier",
  ADMIN_SUPPLIER_EDIT = "editSupplier",
  ADMIN_PRODUCT_NEW = "newProduct",
  ADMIN_PROMOTION_NEW = "newPromotion",
  ADMIN_PROMOTION_EDIT = "editPromotion",
  ADMIN_CLOSURE_EDIT = "editClosure",
  ADMIN_DYNAMIC_LIST = "adminPage",
  ADMIN_DYNAMIC_ITEM_EDIT = "adminPageItem",
  // other
  CALLBACK_OAUTH = "oauthCallback",
  CALLBACK = "callback",
  NOT_FOUND = "not-found",
  BAD_NOT_FOUND = "bad-not-found",
}
