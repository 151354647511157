import { DirectiveBinding } from "vue";
import { debounce } from "@/utils/debounceHelper";

type ObservableHTMLElement = HTMLElement & {
  resizeObserver: ResizeObserver | null;
};

type IResizeListener = (el: HTMLElement, entries: ResizeObserverEntry[] | null) => any;

export const resizeObserverDirective = {
  beforeMount(el: ObservableHTMLElement, onResizeCallback: DirectiveBinding<IResizeListener>) {
    // ResizeObserver fires 10+ times per second
    // at some cases we can increase performance by using debounce
    const debouncedCb = debounce(onResizeCallback.value, 30);

    const observerCallback = (mutations: ResizeObserverEntry[]) => onResizeCallback.modifiers.debounced
      ? debouncedCb(el, mutations)
      : onResizeCallback.value(el, mutations);

    el.resizeObserver = new ResizeObserver(observerCallback);
  },

  mounted(el: ObservableHTMLElement, onResizeCallback: DirectiveBinding<IResizeListener>) {
    el.resizeObserver?.observe(el);

    if (onResizeCallback.modifiers.immediate) {
      onResizeCallback.value(el, null);
    }
  },

  unmounted(el: ObservableHTMLElement) {
    el.resizeObserver?.disconnect();
  },
};
