{
    "en": {
        "inputPlaceholder": "Your Comment...",
        "title": "Comments",
        "noCommentsYet": "Nobody left comments yet"
    },
    "fr": {
        "inputPlaceholder": "Votre Commentaire...",
        "title": "Commentaires",
        "noCommentsYet": "Personne n'a encore laissé de commentaires"
    },
    "ru": {
        "inputPlaceholder": "Ваш комментарий...",
        "title": "Комментарии",
        "noCommentsYet": "Пока никто не оставил комментариев"
    }
}
