{
  "noBom": "Этот продукт не является частью какого-либо BOM",
  "deleteOrder": "Удалить заказ",
  "orderDetailsAction": "{action} детали заказа",
  "open": "Открыть",
  "close": "Закрыть",
  "openOrderDetails": "Открыть детали заказа",
  "editInventoryStrategy": "Редактировать стратегию инвентаризации",
  "selectCurrentPage": "Выбрать текущую страницу",
  "selectAllPages": "Выбрать все страницы",
  "clearSelection": "Очистить выделение",
  "units": "единица | единица | единицы",
  "mixed": "смешанный",
  "myStockMaxValue": "Моя максимальная стоимость запаса",
  "searchASupplier": "Поиск поставщика",
  "supplier": "Поставщик",
  "delayCoverage": "Покрытие задержки",
  "categoriesOfTags": "Категории тегов",
  "here": "здесь",
  "useDefaultBufferPolicy": "использовать политику по умолчанию",
  "supplierDelay": "Покрытие задержки",
  "supplierMode": "Значение",
  "ropLevel": "Уровень точки заказа",
  "quantity": "Количество",
  "coverage": "Покрытие",
  "type": "Тип",
  "time": "Время",
  "stock": "Запас",
  "maxValue": "Максимальное значение",
  "minValue": "Минимальное значение",
  "bufferLevel": "Уровень буфера",
  "bufferLevelTip": "Уровень буфера Flowlity будет влиять на уровень вашего буферного запаса. Чем ниже уровень буфера, тем ниже будет ваш минимальный уровень. Чем выше уровень буфера, тем выше будет ваш минимальный уровень.",
  "back": "Назад",
  "apply": "Применить",
  "select": "Выбрать",
  "strategyType": "Тип стратегии",
  "strategyTypeDesc": "Примените стратегию MTO или MTS для всех продуктов и укажите подробные конфигурации.",
  "supplyBufferPolicy": "Политика буфера",
  "supplyBufferPolicyDesc": "Укажите, будет ли политика буфера Время, Запас, Нет или просто используйте конфигурации по умолчанию.",
  "myStockMax": "Максимальный запас",
  "myStockMaxDesc": "Установите максимальное значение запаса для всех продуктов.",
  "whatDoYouWantToEdit": "Что вы хотите отредактировать?",
  "clearAllFilters": "Очистить все",
  "reset": "Сбросить",
  "saveView": "Сохранить вид",
  "enterName": "Введите название",
  "deleteViewTitle": "Вы хотите удалить вид \"{name}\"?",
  "deleteViewDescription": "Если вы удалите этот вид, вы потеряете сохраненные фильтры, настройки столбцов и сортировку",
  "addView": "Добавить новый вид",
  "reorderViews": "Изменить порядок видов",
  "renameView": "Переименовать вид",
  "name": "Имя",
  "duplicate": "Дублировать",
  "rename": "Переименовать",
  "reorder": "Изменить порядок",
  "addANewView": "Добавить новый вид",
  "defaultView": "Вид по умолчанию",
  "discardModalTitle": "Отменить изменения?",
  "openPlanning": "Открыть планирование",
  "product": "Продукты | Продукт | Продукты",
  "by": "По",
  "day": "Дни | День | Дни",
  "week": "Недели | Неделя | Недели",
  "month": "Месяцы | Месяц | Месяцы",
  "discardChanges": "Отменить изменения",
  "save": "Сохранить",
  "customers": "Клиенты",
  "andXMore": "и еще {count}...",
  "noSuppliersFound": "Поставщики не найдены",
  "noAlertsAvailable": "Нет доступных оповещений",
  "confirm": "Подтвердить",
  "learnMore": "Узнать больше",
  "new": "Новый",
  "days": "дни | день | дни",
  "baseTable": {
    "loadOlderData": "Загрузить старые данные",
    "simulation": "только симуляция",
    "simulationTooltipTitle": "Изменения в твердом предложении",
    "simulationTooltipSubtitle": "Изменения в этой строке не будут сохранены.",
    "simulationTooltipText": "Твердое предложение можно изменить только в вашей ERP."
  },
  "cancel": "Отмена",
  "undo": "Отменить",
  "dateRange": "Диапазон дат",
  "dataJobsAlerts": {
    "error": {
      "subscriptionError": "Произошла ошибка при получении статуса обновления задач данных"
    }
  },
  "backToEditing": "Вернуться к редактированию",
  "delete": "Удалить",
  "deleteOrdersWarningText": "Вы уверены, что хотите удалить выбранные заказы?",
  "deleteWarningCancelButton": "Отмена",
  "deleteWarningConfirmButton": "Да, подтвердить",
  "deleteWarningTitle": "Подтверждение удаления",
  "deliveryDate": "Дата доставки",
  "demand": {
    "chart": {
      "allOrders": "Будущие отгрузки",
      "demandFinalForecast": "Окончательный прогноз",
      "demandForecastFlowlity": "Прогноз Flowlity",
      "demandForecastExternal": "Внешний прогноз",
      "firmOrders": "Подтвержденные отгрузки",
      "plannedOrders": "Планируемые отгрузки",
      "historicalDemand": "Прошлый спрос",
      "confidenceInterval": "Доверительный интервал",
      "maxFlowPred": "Доверительный интервал - макс",
      "minFlowPred": "Доверительный интервал - мин",
      "myforecast": "Мой прогноз",
      "supplierDemand": "Фактическое потребление клиента",
      "title": "Прогнозы"
    }
  },
  "demandTable": {
    "common": {
      "aggregatedView": "Агрегированный вид",
      "clientDemand": "Клиент",
      "demandFinalForecast": "Окончательный прогноз",
      "demandForecastExternal": "Внешний прогноз",
      "salesOrders": "Твердый спрос",
      "updateError": "Произошла ошибка при сохранении значения",
      "updateSuccess": "Значение успешно сохранено"
    },
    "discardChanges": "Отменить",
    "editingMyForecast": "Редактирование",
    "regularView": {
      "demandForecastClient": "Мой прогноз",
      "demandForecastFlowlity": "Прогноз Flowlity",
      "historicalDemand": "Прошлый спрос"
    },
    "saveUpdateButton": "Сохранить",
    "saving": "Сохранение",
    "supplierView": {
      "allOrders": "Будущие отгрузки",
      "firmOrders": "Подтвержденные отгрузки",
      "plannedOrders": "Планируемые отгрузки",
      "historicalDemand": "Фактический спрос клиента"
    }
  },
  "deselectAll": "Снять все выделения",
  "discardTitle": "Отменить изменения?",
  "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
  "discardCancelButton": "Вернуться к редактированию",
  "discardConfirmButton": "Отменить изменения",
  "productsCount": "0 продуктов | 1 продукт | {count} продуктов",
  "tagsCount": "0 тегов | 1 тег | {count} тегов",
  "dropdown": {
    "alerts": {
      "title": "Оповещения"
    },
    "attributes": "Атрибуты | Атрибут: {name} | {count} Атрибуты",
    "customers": "Клиенты",
    "dateRange": "Диапазон дат",
    "deselect": "Снять выделение",
    "deselectAll": "Снять все выделения",
    "products": "Продукты",
    "search": "Поиск {suffix}",
    "selectAll": "Выбрать все",
    "subContractedComponents": "Компоненты",
    "suppliers": {
      "title": "Поставщики | Поставщик: {name} | {count} Поставщики",
      "noSuppliersAvailable": "Нет доступных поставщиков"
    },
    "tags": {
      "title": "Теги | Тег: {name} | {count} Теги",
      "counter": "Нет доступных тегов | {count} тег доступен | {count} тегов доступны",
      "cell": {
        "displayTitle": "- | {name} | {count} тегов"
      }
    }
  },
  "emptyData": "Нет данных для отображения",
  "error": {
    "expiredStock": "Произошла ошибка при получении данных о просроченном запасе",
    "storageSites": "Произошла ошибка при получении данных о местах хранения"
  },
  "header": {
    "editProfile": "Редактировать профиль",
    "lang": "Язык",
    "logout": "Выйти",
    "whatsNew": "Что нового?",
    "settings": "Настройки"
  },
  "credits": {
    "available": "0 доступных оптим кредитов | 1 доступный оптим кредит | {balance} доступных оптим кредитов",
    "getMore": "Получить больше кредитов",
    "currentLimit": "В настоящее время у вас доступно {limit} оптим кредитов в месяц",
    "needTitle": "Сколько кредитов в месяц вам нужно?",
    "credits/month": "кредиты/месяц",
    "requestWillBeSentExplanation": "Ваш запрос будет отправлен на support{'@'}flowlity.com",
    "cancel": "Отмена",
    "topupSubject": "{companyName}: увеличение кредитов для {siteName}",
    "topupBody": "Пожалуйста, увеличьте доступный месячный лимит до {amount} кредитов/месяц для {siteName} / {companyName} (ID сайта: {siteId})",
    "notEnough": "Недостаточно кредитов для выполнения планирования",
    "contactFlowlity": "Получите больше кредитов, связавшись с Flowlity"
  },
  "InputLineCell": {
    "dayMarkedAs": "Этот день отмечен как",
    "empty": "пустой",
    "flowlitySupply": "Рекомендация от Flowlity",
    "supplyIsConfirmed": "Эта поставка подтверждена",
    "areValidated": "подтверждено | подтверждено | подтверждено",
    "fullyFrozen": "полностью заморожено",
    "lotSize": "Размер партии",
    "monthMarkedAs": "Этот месяц отмечен как",
    "moq": "MOQ",
    "originalValue": "Оригинальное значение",
    "partlyFrozen": "частично заморожено",
    "validated": "Внимание, заказ на эту поставку подтвержден",
    "weekMarkedAs": "Эта неделя отмечена как"
  },
  "lastOrderDate": "Крайний срок размещения заказа",
  "lastConfirmedDeliveryDate": "Последняя подтвержденная дата доставки",
  "lastConfirmedDeliveryDateTitle": "Дата доставки",
  "lastConfirmedDeliveryDateSubtitle": "последняя подтвержденная",
  "modal": {
    "backToList": "Вернуться к списку",
    "backToOrder": "Вернуться к заказу",
    "backToCapacityUnit": "Вернуться к единице емкости"
  },
  "no": "Нет",
  "notification": {
    "error": "Ошибка",
    "success": "Успех"
  },
  "dashboard": {
    "actual": "Фактический",
    "daysOfCoverage": "Покрытие запасов",
    "days": "дни",
    "tagDropdownPlaceholder": "Фильтровать результаты по тегу"
  },
  "planning": {
    "chart": {
      "allOrders": "Заказы",
      "currentDay": "D-1",
      "week": "[W]",
      "weekTooltip": "[Неделя]",
      "Expired": "Просрочено",
      "firmOrders": "Твердые заказы",
      "intervalMax": "Flowlity Макс",
      "intervalMin": "Flowlity Мин",
      "Maximum": "Максимум",
      "Minimum": "Минимум",
      "myMinimum": "Мой минимальный запас",
      "reorderPoint": "Точка повторного заказа (ROP)",
      "plannedOrders": "Запланированные заказы",
      "Stock": "Запас",
      "simulatedMin": "Новый минимальный запас",
      "myStockMax": "Мой максимальный запас"
    }
  },
  "planningTable": {
    "aggregatedView": "Агрегированный вид",
    "currentDay": "D-1",
    "restOf": "Остальное",
    "discardChanges": "Отменить изменения",
    "discardCancelButton": "Вернуться к редактированию",
    "discardConfirmButton": "Отменить изменения",
    "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
    "discardTitle": "Отменить изменения?",
    "editingMySupply": "Редактирование",
    "finalForecast": "Окончательный прогноз",
    "justInTime": "Нет данных по запасам для этого продукта по системе \"точно в срок\"",
    "leadTime": "дни выполнения",
    "maximum": "Flowlity Макс",
    "minimum": "Flowlity Мин",
    "myMinimum": "Мой минимальный запас",
    "reorderPoint": "Точка повторного заказа (ROP)",
    "severalWarningsFound": "Обнаружено {count} проблем с продуктом",
    "noStock": "Для этого продукта не получены данные о запасах. Поэтому запас был установлен на 0",
    "noSupplierWarning": "Этот продукт не имеет привязанного поставщика, пожалуйста, добавьте поставщиков",
    "orders": "Мои поставки",
    "productAdminPage": "страница администратора продукта",
    "recomputeSuppliesInfo": "У нас недостаточно исторических данных по этому продукту для расчета новых рекомендаций по поставкам",
    "recomputeSuppliesSuccess": "Рекомендации по поставкам были пересчитаны, обновите страницу, если хотите увидеть их",
    "saveConfirmButton": "Да",
    "saveTitle": "Применить изменения и обновить заказ(ы)?",
    "saveUpdateButton": "Сохранить",
    "saving": "Сохранение",
    "status3Supplies": "Твердые заказы",
    "plannedOrders": "Запланированные заказы",
    "stock": "Запас",
    "averageFinalForecast": "Покрытие запасов в днях",
    "stockExpired": "Просроченные запасы"
  },
  "products": "Продукты",
  "productSearch": {
    "aboveFlowlityMax": "Выше оптимального уровня запасов",
    "alerts": "Оповещения",
    "events": "События",
    "belowFlowlityMin": "Ниже оптимального уровня запасов",
    "expiringStocks": "Просроченные запасы",
    "outOfStock": "Нет в наличии",
    "selectedAlerts": "0 оповещений | 1 оповещение | {count} оповещений",
    "selectedEvents": "0 событий | 1 событие | {count} событий",
    "selectedProductsFiltersTags": "0 тегов | 1 тег | {count} тегов",
    "nextNDays": "{count} след. дней",
    "nextNDaysCapitalized": "@.capitalize:productSearch.nextNDays",
    "wholeHorizon": "Без ограничения",
    "daysToAct": "Решить в течение",
    "daysToActExplanation": "Количество дней, оставшихся до решения проблемы. Этот параметр учитывает параметр «время выполнения», поэтому он отражает срочность решения предупреждения. Если существует несколько поставщиков, учитывается среднее время выполнения заказа",
    "toActWithin": "Решить в теч-ие @:productSearch.nextNDays",
    "alertDate": "Дата предупреждения",
    "alertDateExplanation": "Количество дней до возникновения проблемы",
    "alertDuration": "Длительность предупреждения",
    "alertValue": "Значимость предупреждения",
    "stockAlerts": "Предупреждения склада",
    "demandAlerts": "Предупреждения спроса",
    "externalForecastAlerts": "Предупреждения внешнего прогноза"
  },
  "search": "Поиск по имени",
  "selectedSuppliers": "0 поставщиков | 1 поставщик | {count} поставщиков",
  "selectedSubContractedComponents": "0 компонентов | 1 компонент | {count} компонентов",
  "selectedCustomers": "0 клиентов | 1 клиент | {count} клиентов",
  "selectedAttributes": "0 атрибутов | 1 атрибут | {count} атрибутов",
  "site": "Место",
  "siteDropdown": {
    "discardCancelButton": "Вернуться к редактированию",
    "discardConfirmButton": "Отменить изменения",
    "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
    "discardTitle": "Отменить изменения?"
  },
  "suppliers": "поставщики",
  "table": {
    "cell": {
      "tags": {
        "displayText": "- | {name} | {count} тегов"
      },
      "products": {
        "displayText": "- | {count} | {count}"
      },
      "andMore": "и еще {count}..."
    }
  },
  "updateError": "Не удалось правильно обновить заказ(ы)",
  "updateSuccess": "Заказ(ы) успешно обновлены",
  "yes": "Да",
  "yesterday": "Вчера",
  "today": "Сегодня",
  "loadMore": "Загрузить больше",
  "editing": "Редактирование",
  "loading": "Загрузка...",
  "restoreInitial": "Восстановить начальное",
  "applyToAllPeriods": "Применить ко всем периодам",
  "clearAll": "Очистить все",
  "none": "Нет",
  "toOpenInANewTab": "открыть в новой вкладке",
  "noEntitiesFound": "Не найдено {entities}",
  "byName": "по имени",
  "byNameAndRef": "по имени и ссылке",
  "nMore": "ещё {count}",
  "overdue": "Просроченные",
  "more": "Больше",
  "euro": "Евро, €",
  "greaterThan": "Больше",
  "between": "Между",
  "lessThan": "Меньше",
  "enter": "Введите",
  "advancedConfiguration": "Расширенная конфигурация",
  "internalCustomerForecastTooltip": "Прогноз не распространяется на внутренних клиентов. При расчете используется только твёрдый спрос.",
  "familyConstraints": "Ограничения семейства продуктов",
  "familyTagName": "Наименование тега семейства",
  "familyMOQ": "MOQ семейства",
  "familyUnit": "Единство семьи",
  "familyMOQExplanation": "Этот параметр MOQ применяется к группе продуктов, относящихся к этому семейству продуктов",
  "familyLotSize": "Размер партии семейства",
  "familyLotSizeExplanation": "Этот размер партии применяется к \"уровню семейства\" ко всем продуктам, имеющих общий компонент",
  "tags": "Теги | Тег | Теги",
  "tagCategories": "Категории тегов | Категория тега | Категории тегов",
  "usedToGroupOrders": "Группирует заказы",
  "createTagCategory": "Создать категорию тега",
  "addToNewTagCategory": "Добавить в новую категорию тега",
  "editTagCategory": "Редактировать категорию тега",
  "tagsSelected": "{count} тегов выбрано | {count} тег выбран | {count} тегов выбрано",
  "tagCategoryName": "Название категории тега",
  "tagCategoryExplanation1": "Категории тегов - это система для группировки тегов с одинаковым значением для организации и классификации продуктов",
  "tagCategoryExplanation2": "по их атрибутам или характеристикам. Например, у вас могут быть такие категории тегов, как \"Цвет\", \"Размер\" и \"Материал\", каждая из которых может содержать несколько конкретных тегов. Затем вы можете использовать эти категории в списках продуктов для более точного выбора.",
  "showLess": "Показать меньше",
  "showMore": "Показать больше",
  "createCategory": "Создать категорию",
  "saveCategory": "Сохранить категорию",
  "add": "Добавить",
  "addToTagCategory": "Добавить в категорию тегов",
  "unassignCategories": "Открепить категории",
  "addToANewCategory": "Добавить в новую категорию",
  "useToGroupOrders": "Использовать для группировки заказов",
  "confirmToAssignNTagsToCategory": "Вы уверены, что хотите добавить {count} выбранных тегов в категорию {name}?",
  "confirmToUnassignNTagsToCategory": "Вы уверены, что хотите открепить {count} выбранных тегов от их категорий?",
  "unassign": "Открепить",
  "errorAssignmentTags": "Произошла ошибка при присвоении или откреплении тегов от категории",
  "successAssignmentTags": "{count} тегов были добавлены в категорию {name}",
  "successUnassignmentTags": "{count} тегов были откреплены от их категорий",
  "successUseTagsToGroupOrders": "Теги были обновлены",
  "errorUseTagsToGroupOrders": "Произошла ошибка при использовании выбранных тегов для группировки заказов",
  "confirmUseToGroupOrders": "Вы уверены, что хотите использовать {count} выбранных тегов для группировки заказов?",
  "use": "Использовать",
  "errorDeletingTags": "Произошла ошибка при удалении выбранных тегов",
  "successDeletingTags": "Теги были удалены",
  "deleteNTags": "Удалить {count} тегов?",
  "theseTagsUsedInNProducts": "Эти теги используются в {count} продуктах. Это действие нельзя будет отменить.",
  "bomFetchError": "Произошла ошибка при получении данных BOM",
  "configureColums": "Настроить столбцы"
}