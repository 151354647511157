import { DirectiveBinding } from "vue";

type ModifiedHTMLElement = HTMLElement & {
  clickOutsideHandler?: (e: MouseEvent) => void;
};

export const clickOutsideDirective = {
  beforeMount(el: ModifiedHTMLElement, binding: DirectiveBinding) {
    const handler = (e: MouseEvent) => {
      if (!el.contains(e.target as Element) && el !== e.target) {
        binding.value(e);
      }
    };

    el.clickOutsideHandler = handler;
    document.addEventListener("mousedown", handler);
  },
  unmounted(el: ModifiedHTMLElement) {
    if (el.clickOutsideHandler) {
      document.removeEventListener("mousedown", el.clickOutsideHandler);
      el.clickOutsideHandler = undefined;
    }
  },
};
