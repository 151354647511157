{
  "label": {
    "useDefaultBufferPolicy": "use default policy",
    "supplierDelay": "Delay coverage",
    "supplierMode": "Value",
    "bufferLevel": "Buffer level",
    "ropLevel": "Rop level",
    "quantity": "Quantity",
    "coverage": "Coverage",
    "type": "Type",
    "none": "None",
    "time": "Time",
    "stock": "Stock",
    "maxValue": "My max value",
    "minValue": "My min value"
  },
  "description": {
    "bufferLevel1": "Buffer level is applicable for products with ",
    "bufferLevel2": "strategy and ",
    "bufferLevel3": " source. Products using other strategies will be bypassed.",
    "stockMin1": "Min value is applicable for products with ",
    "stockMin2": "strategy. Products using other strategies will be bypassed.",
    "bufferPolicyStockTip1": "Please be aware that ",
    "bufferPolicyStockTip2": "policy type is applicable for products with",
    "bufferPolicyStockTip3": "strategy only. Products using other strategies will be bypassed.",
    "ropLevelTip1": "ROP value is applicable for products with ",
    "ropLevelTip2": "strategy.",
    "useDefaultTip": "All products will have default buffer policy depending on the strategy they have. You can manage default buffer policies"
  },
  "strategyType": {
    "mts": "MTS",
    "stock": "Stock",
    "demand": "Demand-based - Flowlity AI",
    "myMin": "Flowlity AI + my min",
    "ropAi": "ROP + AI"
  },
  "tooltip": {
    "integrated": "The data is integrated from an external system, therefore the modification is not permitted",
    "bufferLevel": "The Flowlity Buffer Level will impact your buffer stock level. The lower is the buffer level, the lower will be your minimum level. The higher is the buffer level, the higher will be your minimum level.",
    "stockMin": "We will use it as fixed value for the whole horizon"
  },
  "mainTitle": "Edit inventory strategy",
  "category": {
    "strategyType": "Strategy Type",
    "bufferPolicy": "Buffer Policy",
    "bufferLevel": "Buffer Level",
    "rop": "Reorder Point Value",
    "ropAi": "Rop + AI",
    "myStockMin": "My Stock Min",
    "myStockMax": "My Stock Max",
    "changedMessage": "Inventory strategy changes applied"
  },
  "edit": {
    "strategyType": "Edit strategy type",
    "bufferPolicy": "Edit supply buffer policy",
    "bufferLevel": "Edit buffer level",
    "rop": "Edit reorder point value",
    "stockMin": "Edit my stock min",
    "stockMax": "Edit my stock max"
  },
  "mixed": "mixed",
  "here": "here",
  "or": "or"
}
