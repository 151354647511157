import { DirectiveBinding } from "vue";
import { throttle } from "@/utils/throttle";

type ModifiedHTMLElement = HTMLElement & {
  scrollHandler?: () => void;
};

export interface IScrolledDirectivePayload {
  scrolledTop: boolean;
  scrolledBottom: boolean;
}

type IScrolledDirectiveValue = (payload: IScrolledDirectivePayload) => any;

export const scrolledDirective = {
  beforeMount(el: ModifiedHTMLElement, binding: DirectiveBinding<IScrolledDirectiveValue>) {
    const handler = () => {
      const scrolledTop = el.scrollTop > 0;

      const scrolledBottom = el.offsetHeight === 0
        || (el.scrollTop === el.scrollHeight - el.offsetHeight);

      binding.value({ scrolledTop, scrolledBottom });
    };

    el.scrollHandler = throttle(handler);
    el.addEventListener("scroll", el.scrollHandler, { passive: true });
  },
  unmounted(el: ModifiedHTMLElement) {
    if (el.scrollHandler) {
      document.removeEventListener("scroll", el.scrollHandler);
      el.scrollHandler = undefined;
    }
  },
};
