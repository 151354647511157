import { IDemandSource, IPlanningSource } from "@/views/forecast/typings";
import { ISimulationColumnKey } from "@/views/tactical/typings";
import { IOrderLinesListColumnKey, IOrdersListColumnKey } from "@/views/orders/typings";
import { ISite } from "@/typings/models/v2/site";
import { FEATURE_FLAG } from "@/typings/models/v2/features";

export type ITagStrategy = "strategy_or" | "strategy_and";

export enum UserLocaleEnum {
  FR = "fr",
  EN = "en",
  RU = "ru",
}

export enum UserTimeBucketEnum {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export interface IUser {
  id: number;
  name: string;
  isActive: boolean;
  email: string;
  locale: UserLocaleEnum;
  company: {
    id: number;
  };
  sites: ISite[];
  defaultSite: {
    id: number;
  };
  isAdmin: boolean;
  isSuperAdmin: boolean;
  simulationColumnsSetting: (ISimulationColumnKey | string)[];
  demandDrawerSourcesSetting: IDemandSource[];
  planningDrawerSourcesSetting: IPlanningSource[];
  ordersColumnsSetting: IOrdersListColumnKey[];
  orderLinesColumnsSetting: IOrderLinesListColumnKey[];
  orderDetailsColumnsSetting: IOrderLinesListColumnKey[];
  sitesDisplaySetting: number[];
  alertsCriticalityColumnsSettings: number[];
  globalPerPage: number;
  canAccess: (rules: Array<string>) => boolean;
  tagStrategy: ITagStrategy;
  permissions: string[];
  features: FEATURE_FLAG[];
  hasTacticalWithEditionMode: boolean;
  hasTacticalWithSimulationMode: boolean;
  demandTimeBucketSetting: UserTimeBucketEnum;
  demandStartDateRelativeSetting: number;
  demandEndDateRelativeSetting: number;
  planningTimeBucketSetting: UserTimeBucketEnum;
  planningStartDateRelativeSetting: number;
  planningEndDateRelativeSetting: number;
}
