import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  Legend,
  BorderOptions,
  ChartArea,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { verticalCenterPositioner } from "@/components/chart/positioners/tooltip";
import { themeColors } from "@/utils/colors";

export const initializeChartJS = () => {
  Chart.register(
    LineElement,
    LineController,
    CategoryScale,
    LinearScale,
    BarController,
    PointElement,
    Filler,
    BarElement,
    Tooltip,
    Legend,
    annotationPlugin,
  );

  // @ts-ignore
  Tooltip.positioners.verticalCenter = verticalCenterPositioner;
  Chart.defaults.responsive = true;
  Chart.defaults.maintainAspectRatio = false;

  Chart.defaults.font = {
    family: "Inter, sans-serif",
    size: 14,
    lineHeight: 1,
    weight: 400,
  };

  // elements
  Chart.defaults.elements.point.radius = 0;
  Chart.defaults.elements.line.borderCapStyle = "butt";

  // scales
  Chart.defaults.scale.ticks.padding = 7;
  Chart.defaults.scale.ticks.color = themeColors.ui[600];

  Chart.defaults.scale.ticks.font = {
    family: "Inter, sans-serif",
    size: 12,
    lineHeight: 1.5,
    weight: 400,
  };

  Chart.defaults.scales.linear.position = "left";

  Chart.defaults.scales.linear.border = {
    dash: [0, 0],
    width: 1,
    display: false,
  } as BorderOptions;

  Chart.defaults.scales.linear.grid = {
    drawTicks: false,
    color: themeColors.ui[300],
  };

  Chart.defaults.scales.category.border = {
    color: themeColors.ui[500],
    width: 1,
    display: true,
  } as BorderOptions;

  Chart.defaults.scales.category.offset = false;

  Chart.defaults.scales.category.grid = {
    drawTicks: true,
    tickColor: themeColors.ui[500],
    tickLength: 8,
    drawOnChartArea: false,
    offset: false,
  };

  Chart.defaults.scales.category.ticks.backdropPadding = {
    left: 4,
    right: 4,
  } as ChartArea;

  Chart.defaults.scales.category.ticks.backdropColor = themeColors.ui[200];

  // legend
  Chart.defaults.plugins.legend.display = false;

  // tooltip
  Chart.defaults.plugins.tooltip.enabled = true;
  Chart.defaults.plugins.tooltip.mode = "index";
  Chart.defaults.plugins.tooltip.intersect = false;
  Chart.defaults.plugins.tooltip.position = "verticalCenter";

  Chart.defaults.plugins.tooltip.padding = {
    x: 12,
    y: 8,
  };

  Chart.defaults.plugins.tooltip.boxPadding = 4;
  Chart.defaults.plugins.tooltip.caretPadding = 24;
  Chart.defaults.plugins.tooltip.backgroundColor = "rgba(255, 255, 255, 0.92)";
  Chart.defaults.plugins.tooltip.borderWidth = 1;
  Chart.defaults.plugins.tooltip.borderColor = "#E3EAF7";
  Chart.defaults.plugins.tooltip.cornerRadius = 6;
  Chart.defaults.plugins.tooltip.bodyColor = "#303C50";
  Chart.defaults.plugins.tooltip.titleAlign = "left";
  Chart.defaults.plugins.tooltip.titleColor = "#303C50";

  Chart.defaults.plugins.tooltip.titleFont = {
    family: "Inter, sans-serif",
    size: 16,
    weight: 500,
    lineHeight: 1.5,
  };

  Chart.defaults.plugins.tooltip.bodyFont = {
    family: "Inter, sans-serif",
    size: 14,
    weight: 400,
    lineHeight: 2,
  };
};
