import { INotification } from "@/services/notifications/notification.typings";

export function prepareNotification(notification: INotification): INotification {
  if (typeof notification.autoCloseMs === "undefined") {
    notification.autoCloseMs = 3000;
  } else if (notification.autoCloseMs === 0) {
    notification.showCloseBtn = true;
  }

  return notification;
}
