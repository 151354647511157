import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthStore } from "@/store/auth.store";
import Downloader from "js-file-downloader";
import { NotificationService } from "@/services/notifications";
import dayjs from "@/utils/dayjs";
import { apiV1ParamsSerializer, apiV2ParamsSerializer } from "@/utils/axios";
import { useUserStore } from "@/store/user.store";
import { storeToRefs } from "pinia";

interface IAPIServiceOpts {
  serializer(params: any): string;
}

class APIService {
  private readonly _axios: AxiosInstance;

  private readonly baseURL: string;

  constructor(baseURL: string, opts: IAPIServiceOpts) {
    this.baseURL = baseURL;

    this._axios = axios.create({
      baseURL: baseURL,
      headers: {
        // @ts-ignore
        common: {},
      },
      paramsSerializer: opts.serializer,
    });
  }

  get axios(): AxiosInstance {
    return this._axios as AxiosInstance;
  }

  setHeader() {
    if (!this.axios) return;
    const store = useAuthStore();
    const { accessToken } = storeToRefs(store);
    this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken.value}`;

    if (import.meta.env.MODE === "development") {
      this.axios.defaults.headers.common.email = import.meta.env.VITE_DEV_EMAIL;

      this.axios.defaults.headers.common.permissions = import.meta.env.VITE_DEV_PERMISSIONS;
    }
  }

  get<T = any>(resource: string, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(resource, { params, ...config });
  }

  post<T = any>(resource: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.post<T>(resource, data, { params, ...config });
  }

  put<T = any>(resource: string, data?: any, params?: any): Promise<AxiosResponse<T>> {
    return this.axios.put<T>(resource, data, { params });
  }

  patch<T = any>(resource: string, data?: any, params?: any): Promise<AxiosResponse<T>> {
    return this.axios.patch<T>(resource, data, { params });
  }

  delete<T = any>(resource: string, params?: any): Promise<AxiosResponse<T>> {
    return this.axios.delete<T>(resource, { params });
  }

  exportFile(
    sites: { key: number; label: string; }[],
    orderIds: string[] | null,
    supplyIds: string[] | null,
    format: string,
    source: string,
    // eslint-disable-next-line
    successMessage = "",
    start: string | null,
    end: string | null,
    timebucket: string | null,
    isDefaultUnit: boolean,
    statusIdMaximum = 2,
    token = "",
    shouldDownload: boolean = true,
  ) {
    const { accessToken } = storeToRefs(useAuthStore());
    const siteIds: number[] = sites.map(s => s.key);
    const siteNames: string[] = sites.map(s => s.label);
    const siteName: string = siteNames.length > 1 ? "multiple-sites" : siteNames[0];
    const { currentUser } = storeToRefs(useUserStore());
    const companyId: number = currentUser.value.company.id;
    let fileName: string = `flowlity-${source}-${siteName}`;
    // prevent GET caching
    const seed: string = `&t=${new Date().getTime()}`;
    let exportURL = `/api/v1/companies/${companyId}/export?sites[]=${siteIds}&source=${source}&type=${format}${seed}&token=${token}`;

    if (start !== null) {
      exportURL += `&start=${start}`;
      fileName = `${dayjs(start).format("YYYY-MM-DD")}-` + fileName;
    } else {
      fileName = `${dayjs().format("YYYY-MM-DD")}-` + fileName;
    }

    if (end !== null) {
      exportURL += `&end=${end}`;
      fileName += `-${dayjs(end).format("YYYY-MM-DD")}`;
    }

    fileName += `.${format}`;
    if (orderIds !== null) exportURL += `&orders[]=${orderIds}`;
    if (supplyIds !== null) exportURL += `&supplies[]=${supplyIds}`;
    if (timebucket !== null) exportURL += `&timebucket=${timebucket}`;
    exportURL += `&is_default_unit=${isDefaultUnit}`;
    exportURL += `&status_id_maximum=${statusIdMaximum}`;
    if (format === "sftp")
      return this.get(exportURL).then(() =>
        NotificationService.success(successMessage),
      );
    let headers = [
      { name: "Authorization", value: `Bearer ${accessToken.value}` },
    ];

    if (import.meta.env.MODE === "development") {
      headers = [
        { name: "email", value: import.meta.env.VITE_DEV_EMAIL },
        { name: "permissions", value: import.meta.env.VITE_DEV_PERMISSIONS },
      ];
    }

    if (shouldDownload)
      return new Downloader({
        url: `${this.baseURL}${exportURL}`,
        headers,
        filename: fileName,
        timeout: 300000,
      }).then(() => {
        // Called when download ended
      });
    else {
      // We should be able to use dynamic method instead of get only
      return this.get(`${this.baseURL}${exportURL}`);
    }
  }
}

export const baseURL =
  import.meta.env.MODE === "development"
    ? "http://127.0.0.1:5000"
    : `https://${import.meta.env.VITE_GATEWAY}/admin-manager`;

export const baseURLV2 =
  import.meta.env.MODE === "development"
    ? "http://localhost:3000"
    : `https://${import.meta.env.VITE_GATEWAY_V2}/`;


// singletons
export const API = new APIService(baseURL, {
  serializer: apiV1ParamsSerializer,
});

export const API_V2 = new APIService(baseURLV2, {
  serializer: apiV2ParamsSerializer,
});
