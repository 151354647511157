import {
  INotification,
  INotificationContent,
  INotificationOptions,
  INotificationType,
  IResolvedNotificationPayload,
} from "@/services/notifications/notification.typings";
import { emitter } from "@/services/emitter";
import { prepareNotification } from "@/services/notifications/notification.utils";
import { EmitterEvent } from "@/services/emitter/events";

const notify = (type: INotificationType, content: INotificationContent) => {
  const preparedNotification: INotification = prepareNotification({ type, ...content });
  return new Promise<IResolvedNotificationPayload>(resolve => {
    emitter.emit(EmitterEvent.SHOW_NOTIFICATION, { ...preparedNotification, resolve });
  });
};

export const NotificationService = {
  info(message: string, options?: INotificationOptions): Promise<IResolvedNotificationPayload> {
    return notify("info", { message, ...options });
  },
  success(message: string, options?: INotificationOptions): Promise<IResolvedNotificationPayload> {
    return notify("success", { message, ...options });
  },
  error(message: string, options?: INotificationOptions): Promise<IResolvedNotificationPayload> {
    return notify("error", { message, ...options });
  },
};
