import { RouteRecordRaw } from "vue-router";
import {
  adminNotAuthorized,
  supplierNotAuthorized,
  analyticsPageGuard,
  capacityUnitsPageGuard,
} from "@/router/middleware/admin-middleware";
import { ROUTE } from "@/router/enum";

export const routes: RouteRecordRaw[] = [
  {
    path: "/oauth/:companyName/callback",
    component: () => import("@/views/auth/Callback.vue"),
    name: ROUTE.CALLBACK_OAUTH,
  },
  {
    path: "/callback",
    component: () => import("@/views/auth/Callback.vue"),
    name: ROUTE.CALLBACK,
  },
  {
    path: "/",
    component: () => import("@/views/dashboard/HomeSummary/index.vue"),
    name: ROUTE.DASHBOARD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/tactical",
    component: () => import("@/views/tactical/TacticalPage.vue"),
    name: ROUTE.TACTICAL,
    meta: {
      permissions: ["tactical:edition"],
    },
    children: [
      {
        path: ":view(demand|planning)/:entity(products|tags)/:ids/:by(customers)?/:customerId(\\d+)?",
        name: ROUTE.TACTICAL_FORECAST,
        component: () => import("@/views/tactical/TacticalPage.vue"),
      },
    ],
  },
  {
    path: "/:view(demand|planning)",
    component: () => import("@/views/forecast/ForecastsReviewPage.vue"),
    name: ROUTE.FORECAST_LIST,
    meta: {
      permissions: ["demand:read"],
    },
    children: [
      {
        path: ":entity(products|tags)/:ids/:by(customers)?/:customerId(\\d+)?",
        name: ROUTE.FORECAST_DETAILS,
        component: () => import("@/views/forecast/ForecastsReviewPage.vue"),
      },
    ],
  },
  {
    path: "/capacity",
    component: () => import("@/views/capacity/CapacityPage.vue"),
    beforeEnter: capacityUnitsPageGuard,
    name: ROUTE.CAPACITY_LIST,
    children: [
      {
        path: ":id(\\d+)",
        name: ROUTE.CAPACITY_DETAILS,
        beforeEnter: capacityUnitsPageGuard,
        component: () => import("@/views/capacity/CapacityPage.vue"),
        children: [
          {
            path: ":view(planning)/:entity/:ids",
            name: ROUTE.CAPACITY_DETAILS_FORECAST,
            component: () => import("@/views/forecast/ForecastsReviewPage.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/orders",
    component: () => import("@/views/orders/OrdersPage.vue"),
    name: ROUTE.ORDERS_LIST,
    meta: {
      permissions: ["order:read"],
    },
    children: [
      {
        path: ":view(demand|planning)/:entity(products|tags)/:ids",
        name: ROUTE.ORDERS_LIST_FORECAST,
        component: () => import("@/views/forecast/ForecastsReviewPage.vue"),
      },
      {
        path: ":id",
        component: () => import("@/views/orders/OrdersPage.vue"),
        name: ROUTE.ORDER_DETAILS,
        meta: {
          permissions: ["order:read"],
        },
        children: [
          {
            path: ":view(demand|planning)/:entity(products|tags)/:ids",
            name: ROUTE.ORDER_DETAILS_FORECAST,
            component: () => import("@/views/forecast/ForecastsReviewPage.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/analytics",
    name: ROUTE.ANALYTICS,
    component: () => import("@/views/analytics/AnalyticsPage.vue"),
    beforeEnter: analyticsPageGuard,
  },
  {
    path: "/admin",
    component: () => import("@/layouts/AdminLayout.vue"),
    name: ROUTE.ADMIN,
    redirect: "/admin/company",
    children: [
      {
        path: "company",
        component: () => import("@/views/admin/AdminCompanyEdit.vue"),
        name: ROUTE.ADMIN_COMPANY,
        meta: {
          permissions: ["company:read"],
        },
      },
      {
        path: "tags",
        component: () => import("@/views/admin/tags/list/AdminTagsAndCategoriesLists.vue"),
        name: ROUTE.ADMIN_TAGS_LIST,
        meta: {
          permissions: ["company:read"],
        },
        children: [
          {
            path: ":id",
            name: ROUTE.ADMIN_TAG_EDIT,
            component: () => import("@/views/admin/tags/list/AdminTagsAndCategoriesLists.vue"),
            meta: {
              permissions: ["company:read", "tag:write"],
            },
          },
          {
            path: "categories",
            name: ROUTE.ADMIN_TAG_CATEGORIES_LIST,
            component: () => import("@/views/admin/tags/list/AdminTagsAndCategoriesLists.vue"),
            meta: {
              permissions: ["company:read", "tag:write"],
            },
          },
          {
            path: "categories/:id",
            name: ROUTE.ADMIN_TAG_CATEGORY_EDIT,
            component: () => import("@/views/admin/tags/list/AdminTagsAndCategoriesLists.vue"),
            meta: {
              permissions: ["company:read", "tag:write"],
            },
          },
        ],
      },
      {
        path: "site/:siteId",
        component: () => import("@/views/admin/AdminSiteEdit.vue"),
        props: true,
        name: ROUTE.ADMIN_SITE_EDIT,
        meta: {
          permissions: ["site:read"],
        },
      },
      {
        path: "buffer-policy",
        component: () => import("@/views/admin/AdminBufferPolicyEdit.vue"),
        name: ROUTE.ADMIN_BUFFER_POLICY,
        beforeEnter: adminNotAuthorized,
        meta: {
          permissions: ["site:write"],
        },
      },
      {
        path: "user/:userId",
        component: () => import("@/views/admin/AdminUserEdit.vue"),
        props: true,
        name: ROUTE.ADMIN_USER_EDIT,
        meta: {
          permissions: ["user:read"],
        },
      },
      {
        path: "supplier/new",
        component: () => import("@/views/admin/AdminSupplierForm.vue"),
        name: ROUTE.ADMIN_SUPPLIER_NEW,
        meta: {
          permissions: ["partnersite:write"],
        },
      },
      {
        path: "supplier/:supplierId",
        component: () => import("@/views/admin/AdminSupplierForm.vue"),
        props: true,
        name: ROUTE.ADMIN_SUPPLIER_EDIT,
        meta: {
          permissions: ["partnersite:read"],
        },
      },
      {
        path: "product/new",
        component: () => import("@/views/admin/products/index.vue"),
        name: ROUTE.ADMIN_PRODUCT_NEW,
        beforeEnter: adminNotAuthorized,
        meta: {
          permissions: ["productsite:write"],
        },
      },
      {
        path: "promotion/new",
        component: () => import("@/views/admin/AdminPromotionForm.vue"),
        name: ROUTE.ADMIN_PROMOTION_NEW,
        meta: {
          permissions: ["promotion:write"],
        },
      },
      {
        path: "promotion/:promotionId",
        component: () => import("@/views/admin/AdminPromotionForm.vue"),
        props: true,
        name: ROUTE.ADMIN_PROMOTION_EDIT,
        beforeEnter: supplierNotAuthorized,
        meta: {
          permissions: ["promotion:read"],
        },
      },
      {
        path: "closures/:closureId",
        component: () => import("@/views/admin/AdminSiteClosureForm.vue"),
        props: true,
        name: ROUTE.ADMIN_CLOSURE_EDIT,
        beforeEnter: supplierNotAuthorized,
        meta: {
          permissions: ["site:read"],
        },
      },
      {
        path: ":page",
        name: ROUTE.ADMIN_DYNAMIC_LIST,
        component: () => import("@/views/admin/AdminList.vue"),
        props: true,
        meta: {
          permissions: ["company:read"],
        },
      },
      {
        path: ":page/:id",
        name: ROUTE.ADMIN_DYNAMIC_ITEM_EDIT,
        component: () => import("@/views/admin/AdminList.vue"),
        props: {
          page: "products",
        },
        meta: {
          permissions: ["productsite:read"],
        },
      },
    ],
  },
  // pathMatch is the name of the param, e.g., going to /not/found yields
  // { params: { pathMatch: ['not', 'found'] }}
  // this is thanks to the last *, meaning repeated params, and it is necessary if you
  // plan on directly navigating to the not-found route using its name
  {
    path: "/:pathMatch(.*)*",
    name: ROUTE.NOT_FOUND,
    component: () => import("@/layouts/NotFoundLayout.vue"),
  },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  {
    path: "/:pathMatch(.*)",
    name: ROUTE.BAD_NOT_FOUND,
    component: () => import("@/layouts/NotFoundLayout.vue"),
  },
];
