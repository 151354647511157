import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthStore } from "@/store/auth.store";
import { apiV2ParamsSerializer } from "@/utils/axios";
import { storeToRefs } from "pinia";

interface IAPIServiceOpts {
  serializer(params: any): string;
}

class OrAPIService {
  private readonly _axios: AxiosInstance;

  private readonly baseURL: string;

  constructor(baseURL: string, opts: IAPIServiceOpts) {
    this.baseURL = baseURL;

    this._axios = axios.create({
      baseURL: baseURL,
      headers: {
      },
      paramsSerializer: opts.serializer,
    });
  }

  get axios(): AxiosInstance {
    return this._axios;
  }

  setHeader() {
    if (!this.axios) return;
    const store = useAuthStore();
    const { accessToken } = storeToRefs(store);
    this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken.value}`;
  }

  get<T = any>(resource: string, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(resource, { params, ...config });
  }

  post<T = any>(resource: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.post<T>(resource, data, { params, ...config });
  }
}

export const baseURL =
  import.meta.env.MODE !== "production"
    ? "https://or.preprod.flowlity.com"
    : "https://or.app.flowlity.com";

// singletons
export const API_OR = new OrAPIService(baseURL, {
  serializer: apiV2ParamsSerializer,
});
