import { DirectiveBinding } from "vue";

type ObservableHTMLElement = HTMLElement & {
  mutationObserver: MutationObserver | null;
};

export const mutationObserverDirective = {
  beforeMount(el: ObservableHTMLElement, binding: DirectiveBinding) {
    el.mutationObserver = new MutationObserver((mutations: MutationRecord[]) =>
      binding.value(el, mutations[0]),
    );
  },

  mounted(el: ObservableHTMLElement, binding: DirectiveBinding) {
    el.mutationObserver?.observe(el, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });

    if (binding.modifiers.immediate) {
      binding.value(el, null);
    }
  },

  unmounted(el: ObservableHTMLElement) {
    el.mutationObserver?.disconnect();
  },
};
