{
    "alerts": {
        "capacity_below_min": "En dessous de la charge min",
        "capacity_above_limit": "Au-dessus de la limite"
    },
    "capacityAboveLimit": "Au-dessus de la limite",
    "capacityBelowMin": "Problème de charge min",
    "capacity": "Capacité",
    "capacityLeft": "Capacité restante",
    "capacityLimit": "Limite de capacité",
    "productData": "Données produit",
    "byTag": "Par tag",
    "byName": "par nom de l'unité de capacité",
    "byProduct": "Par produit",
    "aboveCapacityLimit": "Au-dessus de la limite de capacité",
    "belowMinLoad": "En-dessous de la charge min",
    "totalLoad": "Charge totale",
    "minLoad": "Charge min",
    "createNewCapacity": "Créer une unité de capacité",
    "creationMessage": "L'unité de capacité a été créée",
    "deleteDescription": "Si vous supprimez cette unité de capacité, vous perdrez toutes les informations connectées. Vous ne pourrez pas annuler cette action.",
    "deleteMessage": "L'unité de capacité a été supprimée",
    "deleteTitle": "Souhaitez-vous supprimer cette capacité ?",
    "deselectAll": "Tout désélectionner",
    "errorMessage": "Une erreur s'est produite",
    "updateMessage": "L'unité de capacité a été mise à jour",
    "of": "sur",
    "timebucketDoesntFitGranularity": "L'unité de capacité par défaut a une granularité de \"{granularity}\". Modifier l'unité de temps en \"{granularity}\" pour l'édition",
    "cannotOpenPlanningForMore100": "Temporaire : seules les unités de capacité contenant jusqu'à 100 produits peuvent être ouvertes dans le planning",
    "list": {
        "table": {
            "header": {
                "actions": "Actions",
                "alerts": "Alertes",
                "description": "Description",
                "isActive": "Statut",
                "name": "Nom de l'unité de capacité",
                "supplier": "Fournisseur"
            },
            "active": "Active",
            "inactive": "Inactive",
            "editTooltip": "Modifier l'unité de capacité",
            "deleteTooltip": "Supprimer l'unité de capacité"
        }
    },
    "formModal": {
        "andMore": "and {count} more ...",
        "create": "Créer une unité de capacité",
        "defaultCapacityLimitTooltip": "Il s'agit de la capacité maximale par granularité qui sera définie pour l'ensemble de l'horizon. Vous pouvez personnaliser cette valeur pour une certaine période dans une vue en tableau ultérieurement.",
        "defaultMinLoadTooltip": "Il s'agit de la capacité minimale par granularité qui sera définie pour l'ensemble de l'horizon. Vous pouvez personnaliser cette valeur pour une certaine période dans une vue en tableau ultérieurement.",
        "duplicateNameError": "Ce nom existe déjà pour ce fournisseur, veuillez entrer un autre nom",
        "editTitle": "Modifier l'unité de capacité",
        "granularity": "Granularité de la capacité",
        "granularityDisabledTooltip": "La granularité de ce site est 'mensuelle', vous ne pouvez pas sélectionner une granularité inférieure.",
        "granularityTooltip": "Définit la période à laquelle la “Charge minimale”, et la “Limite de capacité” seront appliquées",
        "isActiveDescription": "Si cette option est activée, le système affiche des alertes dans la page de l'unité de capacité lorsque les commandes dépassent la limite de capacité ou n'atteignent pas la charge minimale.",
        "maxLoad": "Capacité maximale",
        "maxLoadLessThanMinLoadError": "Cette valeur doit être supérieure à la valeur de la capacité minimale",
        "minLoad": "Charge min",
        "minMaxDescription": "Les valeurs par défaut n'impactent pas les valeurs de l'horizon actuel, mais seront appliquées pour les périodes futures.",
        "minMaxLoadPlaceholder": "Saisir une valeur",
        "name": "Nom de l'unité de capacité",
        "namePlaceholder": "Saisir un nom",
        "products": "Produits",
        "productsDifferentUnits": "Ce produit n'a pas la même unité de mesure que celle qui est selectionnée",
        "requirePartnerTooltip": "Veuillez d'abord sélectionner un fournisseur",
        "requireUnitOfMeasureTooltip": "Veuillez d'abord sélectionner une unité de mesure par défaut",
        "supplier": "Fournisseur",
        "supplierPlaceholder": "Choisir un fournisseur",
        "tagsDifferentUnits": "Au moins un produit de ce tag n'a pas la même unité que l'unité de mesure choisie",
        "title": "Créer une unité de capacité",
        "unit": "Unité de mesure par défaut",
        "unitPlaceholder": "Choisir une unité de mesure",
        "update": "Mettre à jour"
    },
    "modal": {
        "backToList": "Retour à liste"
    }
}
