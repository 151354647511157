{
    "en": {
        "yesterday": "Yesterday",
        "today": "Today"
    },
    "fr": {
        "yesterday": "Hier",
        "today": "Aujourd'hui"
    },
    "ru": {
        "yesterday": "Вчера",
        "today": "Сегодня"
    }
}