{
  "en": {
    "appStatus": "App Status",
    "ready": "Ready",
    "failed": "Failed",
    "inProgress": "In progress",
    "inProgressDots": "In progress...",
    "beReadyAt": "Estimated at {time}",
    "data_received": "Data received",
    "data_integrated": "Data integrated",
    "forecast": "Forecast",
    "supply_plan": "Supply plan",
    "kpis": "KPIs",
    "export_to_sftp": "Export to SFTP",
    "noReportedStatuses": "No reported statuses yet"
  },
  "fr": {
    "appStatus": "Statut de l'application",
    "ready": "Prêt",
    "failed": "Échoué",
    "inProgress": "En cours",
    "inProgressDots": "En cours...",
    "beReadyAt": "Estimé à {time}",
    "data_received": "Données reçues",
    "data_integrated": "Données intégrées",
    "forecast": "Prévision",
    "supply_plan": "Plan d'approvisionnement",
    "kpis": "KPI",
    "export_to_sftp": "Exporter vers SFTP",
    "noReportedStatuses": "Aucun statut n'a encore été signalé"
  },
  "ru": {
    "appStatus": "Статус приложения",
    "ready": "Готово",
    "failed": "Ошибка",
    "inProgress": "В процессе",
    "inProgressDots": "В процессе...",
    "beReadyAt": "Ожидается в {time}",
    "data_received": "Данные получены",
    "data_integrated": "Данные интегрированы",
    "forecast": "Прогноз",
    "supply_plan": "План поставок",
    "kpis": "KPI",
    "export_to_sftp": "Экспорт в SFTP",
    "noReportedStatuses": "Отчетов о статусах пока нет"
  }
}
