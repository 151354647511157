{
  "label": {
    "useDefaultBufferPolicy": "utiliser la politique par défaut",
    "supplierDelay": "Couverture du délai",
    "supplierMode": "Valeur",
    "bufferLevel": "Niveau de stock de sécurité",
    "ropLevel": "Niveau de point de commande",
    "quantity": "Quantité",
    "coverage": "Couverture",
    "type": "Type",
    "none": "Aucun",
    "time": "Temps",
    "stock": "Stock",
    "maxValue": "Ma valeur maximale",
    "minValue": "Ma valeur minimale"
  },
  "description": {
    "bufferLevel1": "Le niveau de stock de sécurité est applicable pour les produits avec ",
    "bufferLevel2": "stratégie et ",
    "bufferLevel3": "source. Les produits utilisant d'autres stratégies seront ignorés.",
    "stockMin1": "La valeur minimale est applicable pour les produits avec ",
    "stockMin2": "stratégie. Les produits utilisant d'autres stratégies seront ignorés.",
    "bufferPolicyStockTip1": "Veuillez noter que le type de politique ",
    "bufferPolicyStockTip2": "est applicable pour les produits avec",
    "bufferPolicyStockTip3": "stratégie uniquement. Les produits utilisant d'autres stratégies seront ignorés.",
    "ropLevelTip1": "La valeur du point de commande est applicable pour les produits avec ",
    "ropLevelTip2": "stratégie.",
    "useDefaultTip": "Tous les produits auront la politique de stock de sécurité par défaut en fonction de la stratégie qu'ils ont. Vous pouvez gérer les politiques de stock de sécurité par défaut"
  },
  "strategyType": {
    "mts": "Fabrication pour stock",
    "stock": "Stock",
    "demand": "Flux tirés - Flowlity IA",
    "myMin": "Flowlity IA + mon min",
    "ropAi": "ROP + IA"
  },
  "tooltip": {
    "integrated": "Cette donnée est intégrée depuis un système externe, la modification n'est donc pas permise",
    "bufferLevel": "Le niveau de stock de sécurité de Flowlity influencera votre niveau de stock de sécurité. Plus le niveau de sécurité est bas, plus votre niveau minimum sera bas. Plus le niveau de sécurité est élevé, plus votre niveau minimum sera élevé.",
    "stockMin": "Nous l'utiliserons comme valeur fixe pour tout l'horizon"
  },
  "mainTitle": "Modifier la stratégie de stock",
  "category": {
    "strategyType": "Type de stratégie",
    "bufferPolicy": "Politique de stock de sécurité",
    "bufferLevel": "Niveau de stock de sécurité",
    "rop": "Valeur du point de commande",
    "ropAi": "ROP + IA",
    "myStockMin": "Mon stock minimum",
    "myStockMax": "Mon stock maximum",
    "changedMessage": "Modifications de la stratégie d'inventaire appliquées"
  },
  "edit": {
    "strategyType": "Modifier le type de stratégie",
    "bufferPolicy": "Modifier la politique de stock de sécurité",
    "bufferLevel": "Modifier le niveau de stock de sécurité",
    "rop": "Modifier la valeur du point de commande",
    "stockMin": "Modifier mon stock minimum",
    "stockMax": "Modifier mon stock maximum"
  },
  "mixed": "mixte",
  "here": "ici",
  "or": "ou"
}
