{
  "demand": "Demande",
  "flowlityAi": "Flowlity IA",
  "myStockMin": "Mon Stock Min",
  "inventoryStrategy": "Stratégie de stock",
  "reorderPoint": "ROP",
  "ropAI": "ROP + IA",
  "atNextDelivery": "à la prochaine livraison",
  "averageNext6M": "moy. futurs 6M",
  "next1YAvg": "moy. futur 1 an",
  "avgStockMinCoverageNext6M": "Stock Min (jours)",
  "avgStockMinQtyNext6M": "Stock Min (qté)",
  "mae": "Prédiction F. - MAE",
  "maeExplanation": "Erreur absolue entre la prédiction Flowlity et la demande passée au cours des {count} derniers jours : ABS(demande - prévision) en quantité",
  "mape": "Prédiction F. - MAPE",
  "mapeExplanation": "Erreur moyenne absolue en pourcentage entre la prédiction Flowlity et la demande passée au cours des {count} derniers jours : ABS(demande - prévision)/demande (en %)",
  "forecastValueAdded": "Prédiction F. - Valeur ajoutée",
  "forecastValueAddedExplanation": "Valeur ajoutée par la prédiction flowlity par rapport à un modèle statistique (Moyenne Mobile des trois derniers mois). Lorsque la valeur est positive la prévision flowlity est meilleure de x%",
  "bufferLevel": "Niv. de stock sécurité",
  "current": "actuel",
  "currentMonth": "mois actuel",
  "Customers": "Clients",
  "Tags": "Tag | Tag | Tags",
  "daysShort": "j",
  "Events": "Événements",
  "Promotions": "Promotions",
  "DefaultUnit": "Unité par défaut",
  "EndOfLife": "Fin de vie",
  "FDemand": "Demande F.",
  "FForecast": "Prédiction F.",
  "firmOrders": "Commandes fermes",
  "forecastSource": "Source de prédiction",
  "forecastSourceMyForecast": "Ma prédiction",
  "forecastSourceFlowlity": "Prédiction flowlity",
  "forecastSourceMrp": "Prédiction externe",
  "FinalForecastAgainstPastDemand": "futur 30j tendance",
  "FinalForecastNext30DaysCur": "futur 30j, €",
  "FinalForecastNext30DaysQty": "futur 30j, qté",
  "FinalForecastNext180DaysQty": "futur 6mois, qté",
  "FinalForecastNext360DaysQty": "futur 1an, qté",
  "FutureDemandNext30DaysCur": "futur 30j, €",
  "FutureDemandNext30DaysQty": "futur 30j, qté",
  "InventoryValue": "Valeur de stock",
  "initialValue": "Valeur Initiale",
  "last3M": "3 derniers mois",
  "last6M": "6 derniers mois",
  "lastMonth": "mois dernier",
  "LeadTime": "Délai de livraison",
  "mixed": "Mixte",
  "nextDeliveryDate": "Prochaine livraison",
  "none": "Aucune",
  "PastDemandLast30DaysCur": "passé 30j, €",
  "PastDemandLast30DaysQty": "passé 30j, qté",
  "PastDemandLast180DaysQty": "passé 6mois, qté",
  "PastDemandLast360DaysQty": "passé 1an, qté",
  "PastDemandLast30DaysTrend": "passé 30j tendance",
  "PDemand": "Demande P.",
  "ProductName": "Désignation produit",
  "Reference": "Référence",
  "reviewDemand": "Revue de la Demande",
  "reviewPlanning": "Revue du Planning",
  "ShelfLife": "Durée de vie",
  "SimilarProducts": "Produits similaires",
  "StockCoverage": "Couverture de stock",
  "stockCoverageAtNextDeliveryTooltip": "Couverture de stock en jours à la prochaine livraison (ferme ou planifiée)",
  "stockCoverageCurrentMonthTooltip": "Couverture de stock en jours - moyenne du mois actuel",
  "stockCoverageNext6MonthsTooltip": "Couverture de stock en jours - moyenne pour les 6 prochains mois",
  "stockCoverageNext1YAvgTooltip": "Couverture de stock en jours - moyenne pour l'année prochaine",
  "StockQty": "Qté de stock",
  "subContracted": "Avec Composants",
  "Suppliers": "Fournisseurs",
  "SupplyBufferPolicy": "Politique de",
  "SupplyBufferPolicySubtitle": "buffer (Appros)",
  "TagName": "Tag",
  "ZeroStockDays": "Jours de stock à zéro",
  "zeroStockDaysLast6MonthsTooltip": "Pourcentage moyen de jours sans stock sur les 6 derniers mois",
  "zeroStockDaysNext1YearTooltip": "Pourcentage moyen de jours sans stock sur l'année prochaine",
  "zeroStockDaysLastMonthTooltip": "Pourcentage moyen de jours sans stock sur le dernier mois",
  "forecastModelDemandType": "Type de demande",
  "demandPredictability": "Prédictibilité",
  "demandPredictabilitySubtitle": "de la demande",
  "demandPredictabilityTooltip": "La prédictibilité de la demande est la qualité des données de demande passées utilisées pour générer la prévision",
  "demandPredictabilityNoTooltip": "Pas de demande passée, une prévision ne peut pas être générée",
  "demandPredictabilityLowTooltip": "La demande passée est limitée et ne permet pas de générer les meilleures prévisions",
  "demandPredictabilityMediumTooltip": "Flowlity génère les meilleures prévisions possibles, mais la demande peut avoir un comportement erratique",
  "demandPredictabilityHighTooltip": "Flowlity génère les meilleures prévisions possibles"
}