{
  "label": {
    "useDefaultBufferPolicy": "использовать политику по умолчанию",
    "supplierDelay": "Покрытие задержки",
    "supplierMode": "Значение",
    "bufferLevel": "Уровень буфера",
    "ropLevel": "Уровень точки заказа",
    "quantity": "Количество",
    "coverage": "Покрытие",
    "type": "Тип",
    "none": "Нет",
    "time": "Время",
    "stock": "Запас",
    "maxValue": "Мое максимальное значение",
    "minValue": "Мое минимальное значение"
  },
  "description": {
    "bufferLevel1": "Уровень буфера применим для продуктов со ",
    "bufferLevel2": "стратегией и ",
    "bufferLevel3": " источником. Продукты, использующие другие стратегии, будут пропущены.",
    "stockMin1": "Минимальное значение применимо для продуктов со ",
    "stockMin2": "стратегией. Продукты, использующие другие стратегии, будут пропущены.",
    "bufferPolicyStockTip1": "Пожалуйста, обратите внимание, что ",
    "bufferPolicyStockTip2": "тип политики применим только для продуктов с",
    "bufferPolicyStockTip3": "стратегией. Продукты, использующие другие стратегии, будут пропущены.",
    "ropLevelTip1": "Значение точки заказа применимо для продуктов со ",
    "ropLevelTip2": "стратегией.",
    "useDefaultTip": "Все продукты будут иметь политику буфера по умолчанию в зависимости от их стратегии. Вы можете управлять политиками буфера по умолчанию"
  },
  "strategyType": {
    "mts": "MTS",
    "stock": "Запас",
    "demand": "На основе спроса - Flowlity AI",
    "myMin": "Flowlity AI + мой минимум",
    "ropAi": "Точка заказа + AI"
  },
  "tooltip": {
    "integrated": "Данные интегрированы из внешней системы, поэтому изменение не разрешено",
    "bufferLevel": "Уровень буфера Flowlity повлияет на уровень вашего буферного запаса. Чем ниже уровень буфера, тем ниже будет ваш минимальный уровень. Чем выше уровень буфера, тем выше будет ваш минимальный уровень.",
    "stockMin": "Мы будем использовать это как фиксированное значение для всего горизонта"
  },
  "mainTitle": "Редактировать стратегию запасов",
  "category": {
    "strategyType": "Тип стратегии",
    "bufferPolicy": "Политика буфера",
    "bufferLevel": "Уровень буфера",
    "rop": "Значение точки заказа",
    "ropAi": "Точка заказа + AI",
    "myStockMin": "Мой минимальный запас",
    "myStockMax": "Мой максимальный запас",
    "changedMessage": "Изменения стратегии запасов применены"
  },
  "edit": {
    "strategyType": "Редактировать тип стратегии",
    "bufferPolicy": "Редактировать политику буфера поставок",
    "bufferLevel": "Редактировать уровень буфера",
    "rop": "Редактировать значение точки заказа",
    "stockMin": "Редактировать мой минимальный запас",
    "stockMax": "Редактировать мой максимальный запас"
  },
  "mixed": "смешанный",
  "here": "здесь",
  "or": "или"
}
