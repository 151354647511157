import {
  BroadcastEvent,
  IBroadcastMessageData,
  IBroadcastEventsMap,
  IBroadcastCallback,
} from "@/services/broadcast-channel/types";

// casts events to the active tab and other open tabs
class Broadcaster {
  private channel: BroadcastChannel = new BroadcastChannel("flowlity-app");
  private subscribers = new Map<BroadcastEvent, IBroadcastCallback<IBroadcastEventsMap[BroadcastEvent]>[]>();

  private notifySubscribers<TEvent extends BroadcastEvent>(
    event: TEvent,
    payload: IBroadcastEventsMap[TEvent],
  ) {
    this.subscribers.get(event)?.forEach(subscriber => subscriber(payload));
  }

  public init() {
    this.channel.addEventListener("message", (message: MessageEvent<IBroadcastMessageData>) => this.notifySubscribers(
      message.data.event,
      message.data.payload,
    ));
  }

  public broadcast<TEvent extends BroadcastEvent>(
    event: TEvent,
    payload: IBroadcastEventsMap[TEvent],
    toOtherTabsOnly: boolean = false,
  ) {
    this.channel.postMessage({
      event,
      payload,
    });

    if (!toOtherTabsOnly) {
      this.notifySubscribers(event, payload);
    }
  }

  public on<TEvent extends BroadcastEvent>(
    event: TEvent,
    callback: IBroadcastCallback<IBroadcastEventsMap[TEvent]>,
  ) {
    if (!this.subscribers.has(event)) {
      this.subscribers.set(event, []);
    }

    this.subscribers.get(event)!.push(callback);
  }

  public off<TEvent extends BroadcastEvent>(
    event: TEvent,
    callback?: IBroadcastCallback<IBroadcastEventsMap[TEvent]>,
  ) {
    if (!this.subscribers.has(event)) return;

    if (!callback) {
      // remove all listeners of this type of event
      const length = this.subscribers.get(event)!.length;
      this.subscribers.get(event)!.splice(0, length);
    } else {
      // remove provided listener of this type of event
      const subscriberIndex = this.subscribers.get(event)!.indexOf(callback) ?? -1;

      if (subscriberIndex !== -1) {
        this.subscribers.get(event)!.splice(subscriberIndex);
      }
    }
  }
}

// singleton instance
export const broadcaster = new Broadcaster();
