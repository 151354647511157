{
  "mustBeFilled": "Doit être rempli",
  "mustBeFilledNamed": "{fieldName} doit être rempli",
  "mustBeANumber": "Doit être un nombre",
  "mustBeANumberNamed": "{fieldName} doit être un nombre",
  "mustBePositive": "Doit être positif",
  "mustBePositiveNamed": "{fieldName} doit être positif",
  "cantBeNegative": "Ne peut pas être négatif",
  "cantBeNegativeNamed": "{fieldName} ne peut pas être négatif",
  "mustBeInteger": "Doit être un entier",
  "mustBeIntegerNamed": "{fieldName} doit être un entier",
  "mustBeBetween": "Doit être compris entre {min} et {max}",
  "mustBeBetweenNamed": "{fieldName} doit être compris entre {min} et {max}",
  "mustBeGreaterThan": "Doit être supérieur à {min}",
  "mustBeGreateThanNamed": "{fieldName} doit être supérieur à {min}",
  "mustBeLessThan": "Doit être inférieur à {max}",
  "mustBeLessThanNamed": "{fieldName} doit être inférieur à {max}",
  "mustBeLaterThan": "Doit être plus tard que {date}",
  "mustBeLaterThanNamed": "{fieldName} doit être plus tard que {date}",
  "mustBeEarlierThan": "Doit être plus tôt que {date}",
  "mustBeEarlierThanNamed": "{fieldName} doit être plus tôt que {date}"
}