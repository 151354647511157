<template>
  <RouterView />
</template>

<script async defer lang="ts" setup>
import { JimoService } from "@/services/jimo";
import { JimoWindow } from "@/services/jimo/jimo.window";
import { onMounted, watch } from "vue";
import { useUserStore } from "@/store/user.store";

declare let window: JimoWindow;

const userStore = useUserStore();

onMounted(() => {
  if (window.jimo != null) {
    return;
  }

  window.jimo = [];
  const s = document.createElement("script");
  s.async = true;
  s.type = "text/javascript";
  s.setAttribute("src", "https://undercity.usejimo.com/jimo-invader.js");
  window.JIMO_PROJECT_ID = import.meta.env.VITE_JIMO_PROJECT_ID;
  window.JIMO_MANUAL_INIT = true;
  document.head.appendChild(s);
});

watch(()=> userStore.currentUser.email, () => {
  JimoService.init(userStore.currentUser.email);
}, { immediate: true });
</script>