import { EventType } from "mitt";
import { INotification } from "@/services/notifications/notification.typings";
import { IConfirmation } from "@/services/confirmation/confirmation.typings";
import { ILoadingOptions } from "@/services/loading/loading.typings";
import { UpsellToSimulationFullModalType } from "@/views/tactical/typings";

export enum EmitterEvent {
  SHOW_NOTIFICATION = "show-notification",
  SHOW_CONFIRMATION = "show-confirmation",
  SHOW_LOADING = "show-loading",
  HIDE_LOADING = "hide-loading",
  SHOW_TOPUP_CREDITS_MODAL = "show-topup-credits-modal",
  SHOW_TACTICAL_UPSELL_MODAL = "show-tactical-upsell-modal",
}

export interface IEmitterEventsMap extends Record<EventType, unknown> {
  [EmitterEvent.SHOW_NOTIFICATION]: INotification;
  [EmitterEvent.SHOW_CONFIRMATION]: IConfirmation;
  [EmitterEvent.SHOW_LOADING]: ILoadingOptions | undefined;
  [EmitterEvent.HIDE_LOADING]: undefined;
  [EmitterEvent.SHOW_TOPUP_CREDITS_MODAL]: undefined;
  // we handle the display of the "Upsell to Simulation Full" modal via emitter events
  // to be able to pass the modal type as a parameter to display the right version of the modal
  [EmitterEvent.SHOW_TACTICAL_UPSELL_MODAL]: UpsellToSimulationFullModalType;
}
