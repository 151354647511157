import { useUserStore } from "@/store/user.store";
import { JimoWindow } from "./jimo.window";

declare let window: JimoWindow;

export const JimoService = {
  init: (email: string) => {

    if (email && window.jimoInit) {
      window.jimo.push(["do", "identify", [email]]);
      window.jimo.push(["set", "user:name", [email]]);
      window.jimoInit();
    }
  },

  push: (args: any) => {
    const userStore = useUserStore();

    if (window.jimo) {
      window.jimo.push(["set", "core:language", [ userStore.currentUser.locale ]]);
      window.jimo.push(args);
    }
  },
};
