{
    "alerts": {
        "capacity_below_min": "Min load issue",
        "capacity_above_limit": "Max capacity issue"
    },
    "capacityAboveLimit": "Capacity limit issue",
    "capacityBelowMin": "Min load issue",
    "capacityLeft": "Capacity left",
    "totalLoad": "Total load",
    "capacity": "Capacity",
    "productData": "Product data",
    "byTag": "By tag",
    "byProduct": "By product",
    "byName": "by capacity name",
    "aboveCapacityLimit": "Above capacity limit",
    "belowMinLoad": "Below min load",
    "capacityLimit": "Capacity limit",
    "minLoad": "Min load",
    "createNewCapacity": "Create capacity unit",
    "creationMessage": "Capacity unit has been created",
    "deleteDescription": "If you delete this capacity unit, you will lose all connected information. You will not be able to undo this action.",
    "deleteMessage": "Capacity unit has been deleted",
    "deleteTitle": "Do you want to delete this capacity unit?",
    "deselectAll": "Deselect all",
    "errorMessage": "An error occurred while saving the capacity unit",
    "updateMessage": "Capacity unit has been updated",
    "of": "of",
    "timebucketDoesntFitGranularity": "The default capacity unit has \"{granularity}\" granularity. Change time bucket to \"{granularity}\" for editing",
    "cannotOpenPlanningForMore100": "Temporary: only capacity units containing up to 100 products can be open in planning",
    "list": {
        "table": {
            "header": {
                "actions": "Actions",
                "alerts": "Alerts",
                "description": "Description",
                "isActive": "Status",
                "name": "Capacity Unit Name",
                "supplier": "Supplier"
            },
            "active": "Active",
            "inactive": "Inactive",
            "editTooltip": "Edit capacity unit",
            "deleteTooltip": "Delete capacity unit"
        }
    },
    "formModal": {
        "andMore": "and {count} more ...",
        "create": "Create capacity unit",
        "defaultCapacityLimitTooltip": "This is value per granularity period that will be set for the full horizon. You can customize this value for certain period in a table view later.",
        "defaultMinLoadTooltip": "This is the default value per granularity period that will be set for the full horizon. You can customise this value for a certain period in a table later.",
        "duplicateNameError": "This name already exists for selected supplier, please enter another name",
        "editTitle": "Edit capacity unit",
        "granularity": "Capacity granularity",
        "granularityDisabledTooltip": "Site granularity is 'Months', you can't select a lower value.",
        "granularityTooltip": "Defines period to which “Min load” and “Capacity limit” will be applied",
        "isActiveDescription": "If active, the system shows you alerts in Capacity unit page when orders exceed Capacity limit or don’t reach Min load.",
        "maxLoad": "Capacity limit",
        "maxLoadLessThanMinLoadError": "This value can’t be less than the default min load",
        "minLoad": "Min load",
        "minMaxDescription": "Default values don’t impact the values of current horizon, but update the values of new periods, which appears progressively.",
        "minMaxLoadPlaceholder": "Enter value",
        "name": "Capacity unit name",
        "namePlaceholder": "Enter name",
        "products": "Products",
        "productsDifferentUnits": "This product doesn't respect to selected default unit of measure",
        "requirePartnerTooltip": "Please select a supplier first",
        "requireUnitOfMeasureTooltip": "Please select a default unit of measure first",
        "supplier": "Supplier",
        "supplierPlaceholder": "Select supplier",
        "tagsDifferentUnits": "At least one product within this tag doesn't respect to selected default unit of measure",
        "title": "Create capacity unit",
        "unit": "Default unit of measure",
        "unitPlaceholder": "Select unit of measure",
        "update": "Save"
    },
    "modal": {
        "backToList": "Back to list"
    }
}
