export const injectSvgSprite = () => {
  const container = document.createElement("div");
  container.style.display = "none";

  const content = import.meta.glob("@/assets/svg/sprite.svg", {
    as: "raw",
    eager: true,
  });

  container.innerHTML = Object.values(content)[0];
  document.body.append(container);
};
